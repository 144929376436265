<template>
  <div>
    <v-btn @click.stop="$router.go(-1)" text class="black--text mb-2"
      ><v-icon left>mdi-arrow-left</v-icon> Voltar</v-btn
    >
    <v-card>
      <v-card-title class="card-title">{{ gameTheme }}</v-card-title>

      <v-card-text>
        <v-row class="mt-2">
          <v-col>
            <div v-html="gameData.content"></div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="card-actions">
        <v-row>
          <v-col class="ml-2">
            <v-btn
              @click="openInteractionDialog('Responder/Concordar')"
              small
              color="#235737"
              class="mr-2 mt-1"
              :loading="loading"
            >
              <v-img class="mr-1" :src="phiIconSrc"></v-img> Responder/Concordar
            </v-btn>
            <v-btn
              @click="openInteractionDialog('Discordar')"
              small
              color="#a35362"
              class="mr-2 mt-1"
              :loading="loading"
            >
              <v-img class="mr-1" :src="phiNegIconSrc"></v-img> Discordar
            </v-btn>
            <v-btn
              @click="openInteractionDialog('Perguntar')"
              small
              color="#3972af"
              class="mr-2 mt-1"
              :loading="loading"
            >
              <v-img class="mr-1" :src="questionIconSrc"></v-img> Perguntar
            </v-btn>
            <v-btn
              @click="openInteractionDialog('Acusar de falácia')"
              small
              color="grey darken-1"
              class="mr-2 mt-1"
              :loading="loading"
            >
              <v-img class="mr-1" :src="phiUpperIconSrc"></v-img> Acusar de
              falácia
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <div class="post-card" v-bind:key="post.id" v-for="post in gamePosts">
      <PostCard :post="post" :userIsModerator="userIsModerator" />
    </div>

    <div
      v-if="globalPageNumber < totalRows / resultsPerPage && gamePosts.length"
      class="d-flex justify-center align-center my-3"
    >
      <v-btn small text @click="nextPage()" class="black--text">
        Carregar mais
      </v-btn>
    </div>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="successDialog = false"
            >Ok</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="interactionDialog" persistent max-width="850">
      <InteractGameForm
        :interactionType="interactionType"
        @close-dialog="interactionDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import phiIcon from "@/assets/fi.png";
import phiNegIcon from "@/assets/fiNeg.png";
import phiUpperIcon from "@/assets/phiUpper4.png";
import questionIcon from "@/assets/question.png";
import InteractGameForm from "@/components/Games/InteractGameForm.vue";
import PostCard from "@/components/Games/PostCard.vue";

export default {
  components: {
    InteractGameForm,
    PostCard,
  },
  computed: {
    roomId() {
      return this.$route.params.roomId;
    },
    gameId() {
      return this.$route.params.gameId;
    },
    gameTheme() {
      return this.gameData.theme
        ? this.gameData.theme
        : "Carregando tema do debate...";
    },
  },
  data() {
    return {
      loading: false,
      successDialog: false,
      interactionDialog: false,
      userIsModerator: false,
      interactionType: null,
      resultsPerPage: 20,
      globalPageNumber: 1,
      totalRows: 0,
      phiIconSrc: phiIcon,
      phiNegIconSrc: phiNegIcon,
      phiUpperIconSrc: phiUpperIcon,
      questionIconSrc: questionIcon,
      editorOptions: {
        modules: { toolbar: false },
      },
      gameData: {
        content: "<p>Carregando conteúdo...</p>",
        theme: null,
      },
      gamePosts: [],
    };
  },
  methods: {
    async getGamePosts(page) {
      try {
        this.loading = true;
        let currentPage = page ? page : 1;

        let params = {
          pagination: 1,
          resultsPerPage: this.resultsPerPage,
          page: currentPage,
        };

        const res = await this.$axios.get(`/jogos/${this.gameId}/posts`, {
          params,
        });

        this.setData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setData(data) {
      const cpData = { ...data };

      console.log(cpData);

      this.totalRows = cpData.posts.count;

      let gamePostsAux = [];

      if (this.gamePosts.length) {
        for (const post of cpData.posts.rows) {
          gamePostsAux.push(post);
        }

        this.gamePosts = [...this.gamePosts, ...gamePostsAux];
      } else {
        this.gamePosts = [...cpData.posts.rows];
      }

      this.gameData = cpData.gameData;

      this.userIsModerator = cpData.userIsModerator;
    },
    async nextPage() {
      try {
        this.globalPageNumber += 1;

        await this.getGamePosts(this.globalPageNumber);
      } catch (error) {
        console.error(error);
      }
    },
    replacesPost(data) {
      const index = this.gamePosts.findIndex((p) => p.id === parseInt(data.id));

      //remove o antigo objeto do array
      this.gamePosts.splice(index, 1);

      //adiciona data no lugar
      this.gamePosts.splice(index, 0, { ...data });
    },
    openInteractionDialog(type) {
      this.interactionType = type;
      this.interactionDialog = true;
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  sockets: {
    connect: function () {
      this.$socket.emit("connectedInGame", this.gameId);
    },
    newPost: function (data) {
      this.gamePosts = [{ ...data }, ...this.gamePosts];
    },
    postDeleted: function (id) {
      const index = this.gamePosts.findIndex((p) => p.id === parseInt(id));

      this.gamePosts.splice(index, 1);
    },
    postEdited: function (data) {
      this.replacesPost(data);
    },
    validatedPost: function (data) {
      this.replacesPost(data);
    },
  },
  created() {
    this.getGamePosts();
    this.$socket.emit("connectedInGame", this.gameId);
  },
};
</script>

<style scoped>
.v-btn {
  color: #ffffff;
  text-transform: capitalize;
}

.card-title,
.card-actions {
  background-color: #f5f5f5;
}
</style>
